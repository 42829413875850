import React from 'react';
import { useStaticQuery, graphql } from 'gatsby';

// components
import CaseStudyTemplate from '../../components/CaseStudyTemplate';

// assets
import Interaction1 from '../../images/case-study/fanclub/interaction1.mp4';
import Interaction2 from '../../images/case-study/fanclub/interaction2.mp4';
import Interaction3 from '../../images/case-study/fanclub/interaction3.mp4';
import Interaction4 from '../../images/case-study/fanclub/interaction4.mp4';
import Interaction5 from '../../images/case-study/fanclub/interaction5.mp4';
import Interaction6 from '../../images/case-study/fanclub/interaction6.mp4';
import Interaction7 from '../../images/case-study/fanclub/interaction7.mp4';
import Result from '../../images/case-study/fanclub/result.svg';

const FanclubCaseStudy = () => {
  const data = useStaticQuery(graphql`
    query {
      logo: file(relativePath: { eq: "case-study/fanclub/logo.png" }) {
        childImageSharp {
          gatsbyImageData(layout: CONSTRAINED)
        }
      }

      hero: file(relativePath: { eq: "case-study/fanclub/hero.png" }) {
        childImageSharp {
          gatsbyImageData(layout: CONSTRAINED)
        }
      }

      problem: file(relativePath: { eq: "case-study/fanclub/problem.png" }) {
        childImageSharp {
          gatsbyImageData(layout: CONSTRAINED)
        }
      }
    }
  `);

  const caseStudyData = {
    logo: data.logo.childImageSharp.gatsbyImageData,
    name: 'Chrono',
    date: 'Oct 2021',
    title: (
      <div className="banner__title">
        Establish the{' '}
        <span className="banner__title--highlight">connection</span> with the
        people you follow
      </div>
    ),
    platforms: ['phone', 'web'],
    appLinks: [
      { type: 'play-store', path: '/' },
      { type: 'app-store', path: '/' },
    ],
    thumbnail: data.hero.childImageSharp.gatsbyImageData,
    description:
      'Ever wanted to interact with your best fans who have always supported you in your ups and downs or ever wanted to be that best fan to be able to do one-on-one video chat with your favorite creator.',
    expandCTA: 'We love to solve',
    problem: [
      {
        text: 'The whole idea revolve around to give accessibilty to the creators to interact with their (best) fans. So, they can build their fanbase as well as they can promote and generate some revenue. Fans can go on a one-on-one vide chat with their favorite creators (yes, you heard that right).',
        image: Interaction1,
      },
      {
        text: 'Creating a social platform which is totally community driven seems to be a big hurdle, with some innovative ideas & layouts to make it stand out (it wasn’t easy), analysing different platforms & different needs of the current available platform (seriously, we did that as well).',
        image: data.problem.childImageSharp.gatsbyImageData,
      },
    ],
    method: {
      text: 'Implementing the research and the client’s feedback helps us to create the backbones of the final outcome. Drawing sketches, brainstorming and much more (just some old school methods).',
      image: Interaction2,
    },
    design: [
      {
        title: 'MOBILE APP',
        text: 'Uncountable screens and frames (not gonna disclose the no. tough), and uncountable hours spent, and we finally created it.',
        image: Interaction3,
      },
      { imageOne: Interaction4, imageTwo: Interaction5 },
      {
        title: 'WEBSITE',
        text: 'We also created a website for the users to learn about the FanClub and how they can join the fun by downloading the mobile app.',
        image: Interaction6,
      },
      {
        text: 'Content Creators can use this platform as an opportunity to monetize their quality content and promote their work.',
        image: Interaction7,
      },
    ],
    result: {
      text: 'After hours of dedication, our team came up with this design. It was such an amazing experience.”Excellence is a habit”',
      image: Result,
    },
    next: ['moja', 'flexnest'],
  };

  return (
    <>
      <CaseStudyTemplate {...caseStudyData} />
    </>
  );
};

export default FanclubCaseStudy;
